.header {
  background-color: #494949;
  color: #fff; 
}

.floating-button {
  position: fixed;  
  right: 20px;   
  top: 110px;    
  padding: 10px 20px;
  background-color: #007bff;   
  color: #fff;      
  border: none;       
  border-radius: 50%;      
  cursor: pointer;   
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.floating2-button {
  position: fixed;
  right: 20px;    
  top: 160px;      
  padding: 10px 20px;
  background-color: #930303;   
  color: #fff;       
  border: none;       
  border-radius: 50%;     
  cursor: pointer;   
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);     
}
.floating3-button {
  position: fixed;
  right: 20px;    
  top: 210px;      
  padding: 10px 20px;
  background-color: #229f56;   
  color: #fff;       
  border: none;       
  border-radius: 50%;     
  cursor: pointer;   
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);     
}
.floating4-button {
  position: fixed;
  right: 20px;    
  top: 260px;      
  padding: 10px 20px;
  background-color: #ee73d9;   
  color: #fff;       
  border: none;       
  border-radius: 50%;     
  cursor: pointer;   
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);     
}
.floating5-button {
  position: fixed;
  right: 20px;    
  top: 310px;      
  padding: 10px 20px;
  background-color: #55d7f8;   
  color: #fff;       
  border: none;       
  border-radius: 50%;     
  cursor: pointer;   
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);     
}
.floating6-button {
  position: fixed;
  right: 20px;    
  top: 360px;      
  padding: 10px 20px;
  background-color: #d1ab00;   
  color: #fff;       
  border: none;       
  border-radius: 50%;     
  cursor: pointer;   
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);     
}
*{
    margin:0;
  }
  header{
    height:170px;
    color:#FFF;
    font-size:20px;
    font-family:Sans-serif;
    background:#009688;
    padding-top:30px;
    padding-left:50px;
  }
  .contenedor{
    width:90px;
    height:240px;
    position:absolute;
    right:0px;
    bottom:0px;
  }
  .botonF1{
    width:60px;
    height:60px;
    border-radius:100%;
    background:#F44336;
    right:0;
    bottom:0;
    position:absolute;
    margin-right:16px;
    margin-bottom:16px;
    border:none;
    outline:none;
    color:#FFF;
    font-size:36px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition:.3s;  
  }
  span{
    transition:.5s;  
  }
  .botonF1:hover span{
    transform:rotate(360deg);
  }
  .botonF1:active{
    transform:scale(1.1);
  }
  .btn{
    width:40px;
    height:40px;
    border-radius:100%;
    border:none;
    color:#FFF;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    font-size:28px;
    outline:none;
    position:absolute;
    right:0;
    bottom:0;
    margin-right:26px;
    transform:scale(0);
  }
  .botonF2{
    background:#2196F3;
    margin-bottom:85px;
    transition:0.5s;
  }
  .botonF3{
    background:#673AB7;
    margin-bottom:130px;
    transition:0.7s;
  }
  .botonF4{
    background:#009688;
    margin-bottom:175px;
    transition:0.9s;
  }
  .botonF5{
    background:#FF5722;
    margin-bottom:220px;
    transition:0.99s;
  }
  .animacionVer{
    transform:scale(1);
  }
  